<template>
  <van-pull-refresh v-model="refreshing" @refresh="loadList">
    <van-list v-model:loading="loading" :finished="finished" finished-text="数据有5分钟延迟且仅展示最近30条记录" style="min-height: 100vh;" @load="loadList">
      <van-empty v-if="!lists.length" description="暂无数据" />
      <template v-else>
        <van-cell v-for="item in lists" :key="item" :title="time2datetime(item.created_at * 1000)" :value="`${item.amount} Tokens`" />
      </template>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import { Empty, Toast, List, PullRefresh } from 'vant';
import { setDocumentTitle } from "@/utils/domUtil";
import { hideWechatOptionMenu, time2datetime } from "@/utils/util";
import { onMounted, ref, reactive } from "@vue/runtime-core";
import { getAwardList } from "@/api/nijibot.service";
export default {
  components: {
    [Empty.name]: Empty,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  setup() {
    const refreshing = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    const lists = reactive([]);

    const loadList = async () => {
      try {
        const { result } = await getAwardList();
        lists.length = 0;
        Object.assign(lists, result.items);
        refreshing.value = false;
        loading.value = false;
        finished.value = true;
      } catch (error) {
        refreshing.value = false;
        loading.value = false;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast(msg ? msg : "未知错误");
      }
    };

    onMounted(() => {
      setDocumentTitle('奖励明细');
      hideWechatOptionMenu();
    });

    return { refreshing, loading, finished, lists, loadList, time2datetime }
  }
};
</script>
